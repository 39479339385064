<script setup>
const { isOnboardOpen } = useDashboard();

// Step state
const currentStep = ref(0);
const steps = ["OnboardStep1", "OnboardStep2"]; // Add all steps here

const goToNextStep = () => {
  if (currentStep.value < steps.length - 1) {
    currentStep.value++;
  }
};

const goToPreviousStep = () => {
  if (currentStep.value > 0) {
    currentStep.value--;
  }
};
</script>
<template>
  <UModal
    v-model="isOnboardOpen"
    class="flex items-center justify-center"
    :ui="{
      width: 'w-full sm:max-w-2xl',
      overlay: {
        background: 'bg-black/75 dark:bg-black/75 backdrop-blur',
      },
    }"
  >
    <div
      class="rounded-xl relative flex flex-col h-full w-full items-stretch overflow-hidden shadow-[0px_14px_34px_#000000]"
    >
      <Transition mode="in-out" name="fade-in">
        <!-- Dynamic Step -->
        <component
          :is="steps[currentStep]"
          :key="currentStep"
          @next="goToNextStep"
        />
      </Transition>
    </div>

    <!-- Back Button -->
    <UButton
      v-if="currentStep > 0"
      icon="i-heroicons-arrow-left"
      class="absolute left-4 top-4 w-8 h-8 flex items-center justify-center rounded-full bg-gray-800 hover:bg-gray-700 transition"
      @click="goToPreviousStep"
    />

    <UButton
      icon="i-heroicons-x-mark"
      class="absolute right-4 top-4 w-8 h-8 flex items-center justify-center rounded-full bg-gray-800 hover:bg-gray-700 transition"
      @click="isOnboardOpen = false"
    />
  </UModal>
</template>

<style>
.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.6s ease;
}
.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}

.slide-down-enter-active {
  transition: transform 0.6s ease, opacity 0.6s ease;
}
.slide-down-enter-from {
  transform: translateY(-20px);
  opacity: 0;
}

.slide-up-enter-active {
  transition: transform 0.6s ease, opacity 0.6s ease;
}
.slide-up-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
</style>
